<template>
  <div :class="classCard()">
    <div class="gestao case">
      <a :href="route" target="_blank">
        <img class="gestao-image img-loaded" v-lazy="lazyImg" :alt="imgAlt" />
        <h3 class="gestao-titulo">
          <i :class="icon"></i>
        </h3>
        <p class="gestao-descricao">
          {{ desc }}
        </p>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    /*  "titleImage", */
    "mais",
    "icon",
    "desc",
    "route",
    "colMd",
    "lazyImg",
    "imgAlt"
  ],
  methods: {
    classCard() {
      var $class = "col-sm-12 slick-slide slick-cloned";
      $class =
        $class +
        (this.colMd
          ? "col-md-6 col-lg-4 col-xl-" + this.colMd
          : " col-md-6 col-lg-6 col-xl-4");
      return $class;
    }
  }
};
</script>
